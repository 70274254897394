import { defineMessages } from 'react-intl';

export const ofllineMessages = defineMessages({
    'offline.title': {
        id: 'v4_website.offline.title',
        defaultMessage: 'Unfortunately, this page is not publicly available.'
    },
    'offline.descriprion': {
        id: 'v4_website.offline.descriprion',
        defaultMessage: 'If you are an admin, you can log in to manage the platform. If you are a normal visitor, you can still log in to see your personal dashboard.'
    },
    'offline.btn.login': {
        id: 'v4_website.offline.btn.login',
        defaultMessage: 'Log in with Clubee'
    },
    'offline.btn.go.to.dashboard': {
        id: 'v4_website.offline.btn.go.to.dashboard',
        defaultMessage: 'Go to personal dashboard'
    }
})