import { useIntl } from 'react-intl';
import React from 'react';
import { ofllineMessages } from './messages';
import WebisteErrorPage from 'components/Error/WebsiteErrorPage';

export default function OfflineMessage(): JSX.Element {
    const intl = useIntl()

    return (
        <WebisteErrorPage
            pageTitle={intl.formatMessage(ofllineMessages['offline.title'])}
            title={intl.formatMessage(ofllineMessages['offline.title'])}
            message={intl.formatMessage(ofllineMessages['offline.descriprion'])}
        />
    )
}