import { useIntl } from 'react-intl';
import React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';

import MainLayout from 'components/Layout/MainLayout';
import { Headline3, Paragraph1 } from '@/shared/components/Typography';
import PrimaryButton from '@/shared/components/Button/PrimaryButton';
import { useAuth } from 'contexts/auth';
import { makeStyles } from '@material-ui/core/styles';
import { ITheme } from '@/shared/styles/MuiTheme';
import ClubLogo from 'components/Layout/WebsiteHeader/Elements/ClubLogo';
import { useClub } from 'contexts/club';
import { getOriginFromUrl } from 'utils/clubs';
import { ofllineMessages } from 'components/OfflineMessage/messages';

const offlineStyles = makeStyles((theme: ITheme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: '10%',
        alignItems: 'center',
    },
    inner: {    
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(4),
        maxWidth: '550px'
    },
    clubLogo: {
        margin: theme.spacing(4, 0)
    },
    title: {
        textAlign: 'center',
        marginTop: theme.spacing(2)
    },
    description: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
    button: {
        margin: theme.spacing(5, 0)
    }
}))

interface IProps {
    title: string
    pageTitle: string,
    message: string,
}

export default function WebisteErrorPage({ pageTitle, title, message }: IProps ): JSX.Element {
    const classes = offlineStyles()
    const intl = useIntl()
    const { token } = useAuth()
    const { prefix } = useClub()
    const router = useRouter()
    const origin = getOriginFromUrl(prefix, router.asPath)

    return (
        <MainLayout>
            <Head>
                <title>{pageTitle}</title>
            </Head>
            <div className={classes.root}>
                <div className={classes.inner}>
                    <ClubLogo size={48} />
                    <div className={classes.title}>
                        <Headline3>{title}</Headline3>
                    </div>
                    {token ? (
                        <>
                            <div className={classes.button}>
                                <PrimaryButton isClubExternal href='/dashboard'>{intl.formatMessage(ofllineMessages['offline.btn.go.to.dashboard'])}</PrimaryButton>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={classes.description}>
                                <Paragraph1>{message}</Paragraph1>
                            </div>
                            <div className={classes.button}>
                                <PrimaryButton isClubExternal href={`/user/login?origin=${origin}`}>{intl.formatMessage(ofllineMessages['offline.btn.login'])}</PrimaryButton>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </MainLayout>
    )
}